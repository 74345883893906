<template>
    <h3 class="chart_title">
        <img :src="require(`@/assets/images/${option.img}`)" :alt="option.name">
        {{option.name}} <span class="tag" v-if="option.unit">{{option.unit.value}}</span>
    </h3>
    <div class="widgets_wpr" @click="drawer_type = option.name;">
        <div class="header" v-if="option.name !== 'Vitamins' && option.name !== 'Minerals' && option.name !== 'Supplements' && option.name !== 'Pain' && option.name !== 'Workout' && option.name !== 'Sleep'">
            <div class="scope_box" @click="selectScope = !selectScope;" v-click-outside="closeSelectScope" v-if="option.name == 'Circumference'">
                Choose 3 areas to compare<i class="fas fa-angle-down"></i>
                <div class="dropdown_wpr" :class="selectScope ? 'active' : ''">
                    <ul>
                        <li v-for="(item, i) in option.chart" :key="i" @click.stop="handleScopes(item, scopes);">
                            <label class="checkbox">
                                <span :class="{'active' : scopes.findIndex((att) => att.name === item.name) !== -1 }"><i class="fas fa-check"></i></span>
                                <h5>{{ item.name }}</h5>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <label :for="`${option.name}_show_goals`" class="switch_option capsule_btn p-0 m-0 border-0" v-if="option.name !== 'Circumference'">
                <h5 class="mr-3">Show Goals</h5>
                <input type="checkbox" :id="`${option.name}_show_goals`" v-model="show_goal" :true-value="1" :false-value="0" hidden>
                <div><span></span></div>
            </label>
            <div class="item_unit" v-if="option.unit">Unit : <span v-if="option.unit">{{option.unit.value}}</span></div>
            <div class="chart_setup" v-tooltip="`Update Goal`" v-click-outside="closeUpdateGoal">
                <i class="fas fa-cog" @click="updateGoal = !updateGoal;"></i>
                <div class="goal_option" :class="{'active' : updateGoal}" v-if="chart.series.length == 1">
                    <div class="goal_item">
                        <h4>
                            Range
                            <label :for="`${option.name}_is_range`" class="switch_option capsule_btn p-0 m-0 border-0">
                                <input type="radio" :id="`${option.name}_is_range`" :name="`${option.name}_goal_range`" v-model="rangeGoals" :value="1" hidden>
                                <div><span></span></div>
                            </label>
                        </h4>
                        <ul>
                            <li>
                                <label>High Limit</label>
                                <div class="field_area">
                                    <input type="number" min="0" placeholder="0" v-model="high_limit">
                                    <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                </div>
                            </li>
                            <li>
                                <label>Low Limit</label>
                                <div class="field_area">
                                    <input type="number" min="0" placeholder="0" v-model="low_limit">
                                    <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="goal_item">
                        <h4>
                            Specific Target
                            <label :for="`${option.name}_not_range`" class="switch_option capsule_btn p-0 m-0 border-0">
                                <input type="radio" :id="`${option.name}_not_range`" :name="`${option.name}_goal_range`" v-model="rangeGoals" :value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </h4>
                        <ul>
                            <li>
                                <label>Select Target</label>
                                <div class="target_drops" @click="updateTarget = !updateTarget">
                                    {{ selectSpecificTarget }} <i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="{'active' : updateTarget}">
                                        <ul>
                                            <li v-for="(target, t) of specificTargets" :key="t" @click="selectSpecificTarget = target;">{{ target }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label>Value</label>
                                <div class="field_area">
                                    <input type="number" min="0" placeholder="0">
                                    <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="action_wpr">
                        <button class="btn cancel_btn" @click="updateGoal = false;">Cancel</button>
                        <button class="btn save_btn" @click="saveGoal()">Save</button>
                    </div>
                </div>
                <div class="goal_option" :class="{'active' : updateGoal}" v-else>
                    <div class="goal_item">
                        <h4>
                            Range
                            <label :for="`${option.name}_is_range`" class="switch_option capsule_btn p-0 m-0 border-0">
                                <input type="radio" :id="`${option.name}_is_range`" :name="`${option.name}_goal_range`" v-model="rangeGoals" :value="1" hidden>
                                <div><span></span></div>
                            </label>
                        </h4>
                        <template v-if="option.name == 'Circumference'">
                            <div class="subgoal_item" v-for="(sub, s) in option.chart" :key="s">
                                <h4 class="title">{{ sub.name }}</h4>
                                <ul>
                                    <li>
                                        <label>High Limit</label>
                                        <div class="field_area">
                                            <input type="number" min="0" placeholder="0">
                                            <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <label>Low Limit</label>
                                        <div class="field_area">
                                            <input type="number" min="0" placeholder="0">
                                            <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template v-else>
                            <div class="subgoal_item" v-for="(sub, s) in chart.series" :key="s">
                                <h4 class="title">{{ sub.name }}</h4>
                                <ul>
                                    <li>
                                        <label>High Limit</label>
                                        <div class="field_area">
                                            <input type="number" min="0" placeholder="0" v-model="chart.series[s].markLine.data[0].yAxis" @change="handleHighMark(s)">
                                            <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <label>Low Limit</label>
                                        <div class="field_area">
                                            <input type="number" min="0" placeholder="0" v-model="chart.series[s].markLine.data[1].yAxis" @change="handleLowMark(s)">
                                            <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </div>
                    <div class="goal_item">
                        <h4>
                            Specific Target
                            <label :for="`${option.name}_not_range`" class="switch_option capsule_btn p-0 m-0 border-0">
                                <input type="radio" :id="`${option.name}_not_range`" :name="`${option.name}_goal_range`" v-model="rangeGoals" :value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </h4>
                        <template v-if="option.name == 'Circumference'">
                            <div class="subgoal_item" v-for="(sub, s) in option.chart" :key="s">
                                <h4 class="title">{{ sub.name }}</h4>
                                <ul>
                                    <li>
                                        <label>Select Target</label>
                                        <div class="target_drops" @click="openTargetType($event)">
                                            {{ selectSpecificTarget }} <i class="fas fa-angle-down"></i>
                                            <div class="dropdown_wpr">
                                                <ul>
                                                    <li v-for="(target, t) of specificTargets" :key="t" @click="selectSpecificTarget = target;">{{ target }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <label>Value</label>
                                        <div class="field_area">
                                            <input type="number" min="0" placeholder="0">
                                            <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template v-else>
                            <div class="subgoal_item" v-for="(sub, s) in chart.series" :key="s">
                                <h4 class="title">{{ sub.name }}</h4>
                                <ul>
                                    <li>
                                        <label>Select Target</label>
                                        <div class="target_drops" @click="openTargetType($event)">
                                            {{ selectSpecificTarget }} <i class="fas fa-angle-down"></i>
                                            <div class="dropdown_wpr">
                                                <ul>
                                                    <li v-for="(target, t) of specificTargets" :key="t" @click="selectSpecificTarget = target;">{{ target }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <label>Value</label>
                                        <div class="field_area">
                                            <input type="number" min="0" placeholder="0">
                                            <span class="unit" v-if="option.unit">{{option.unit.value}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </div>
                    <div class="action_wpr">
                        <button class="btn cancel_btn" @click="updateGoal = false;">Cancel</button>
                        <button class="btn save_btn" @click="saveGoal()">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <vue-echarts :option="chart" :style="`height: ${canvas_height}px`" @click="selectBar" ref="chart"></vue-echarts>
        <!-- {{ chart.series.length }} -->
        <span class="chart_title" v-if="option.unit">{{option.unit.value == '%' ? 'Percent' : option.unit.value}}</span>
        <div class="slider_cover"></div>
    </div>
    <div class="modal track_modal" v-if="track_details" :class="{ 'active' : track_details }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Details
                    <!-- <span>{{ track_data.date }}, {{ new Date().getFullYear() }}</span> -->
                </h1>
                <button class="close_btn left_out" @click="track_details = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="section_title">{{ track_data.date }}, {{ new Date().getFullYear() }}</div>
                <div v-if="drawer_type == 'Pain'">
                    <div class="pain_limit">
                        <div class="pain_item" v-for="(item, i) in chart.series" :key="i">
                            <div class="positions">
                                <label>{{item.name}}</label>
                                <ul>
                                    <li v-for="(pos, p) in option.positions[i]" :key="p">{{ pos }}</li>
                                </ul>
                            </div>
                            <h4>
                                <div>{{item.data[track_data.dataIndex][2]}}/10</div>
                                <label>Pain</label>
                            </h4>
                        </div>
                    </div>

                    <div class="section_title">Notes</div>
                    <div class="additional_notes">Quisque nec lorem et mauris lobortis iaculis. Curabitur eu lorem vel odio facilisis fringilla sed vel ante. Suspendisse potenti.</div>

                    <div class="section_title">History <span class="show_hide" @click="showHistory = !showHistory">{{ showHistory ? 'Hide' : 'Show' }}</span></div>
                    <table class="chart_data" v-show="showHistory">
                        <tr>
                            <th>Date</th>
                            <th v-for="(item, i) in chart.series" :key="i">{{item.name}}</th>
                        </tr>
                        <tr v-for="(date, d) in chart.xAxis.data" :key="d">
                            <td class="date">{{date}}</td>
                            <td width="100" v-for="(item, i) in chart.series" :key="i">
                                <h5>
                                    {{ item.data[d][2] }}/10
                                </h5>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-else>
                    <div class="range_limit">
                        <!-- <div class="half_pie_wpr">
                            <vue-echarts :option="pie_chart" :style="`height: 300px`" ref="pie_chart"></vue-echarts>
                        </div> -->
                        <div class="scope_details">
                            <div class="scope_item" v-for="(scope, s) in chart.series" :key="s">
                                <h4>{{scope.name}}:</h4>
                                <div class="item_data">
                                    {{scope.data[track_data.dataIndex]}}<span v-if="option.unit">{{option.unit.value}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="option.name == 'Circumference'">
                            <template v-for="(item, i) in option.chart" :key="i">
                                <h3>
                                    {{item.name}}
                                    <div class="range_details">
                                        <span>High Limit: {{ item.markLine.data[0].yAxis }}</span>
                                        <span>Low Limit: {{ item.markLine.data[1].yAxis }}</span>
                                        <span v-if="option.unit">Unit: {{ option.unit.value }}</span>
                                    </div>
                                </h3>
                                <ul>
                                    <!-- <li><h5>Date:</h5>{{ track_data.date }}</li> -->
                                    <li><h4><label>Value:</label><div>{{item.data[track_data.dataIndex]}}<span v-if="option.unit">{{option.unit.value}}</span></div></h4></li>
                                    <li>
                                        <h4><label>Status:</label>{{ item.data[track_data.dataIndex] > item.markLine.data[0].yAxis ? 'High' : item.data[track_data.dataIndex] < item.markLine.data[1].yAxis ? 'Low' : 'In Range'}}</h4>
                                        <div class="flag" :class="item.data[track_data.dataIndex] > item.markLine.data[0].yAxis ? 'high' : item.data[track_data.dataIndex] < item.markLine.data[1].yAxis ? 'low' : 'winner'">
                                            {{ item.data[track_data.dataIndex] > item.markLine.data[0].yAxis ? 'H' : item.data[track_data.dataIndex] < item.markLine.data[1].yAxis ? 'L' : '★'}}
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                        <div v-else>
                            <template v-for="(item, i) in chart.series" :key="i">
                                <h3>
                                    {{item.name}}
                                    <div class="range_details">
                                        <span>High Limit: {{ item.markLine.data[0].yAxis }}</span>
                                        <span>Low Limit: {{ item.markLine.data[1].yAxis }}</span>
                                        <span v-if="option.unit">Unit: {{ option.unit.value }}</span>
                                    </div>
                                </h3>
                                <ul>
                                    <!-- <li><h5>Date:</h5>{{ track_data.date }}</li> -->
                                    <li><h4><label>Value:</label><div>{{item.data[track_data.dataIndex]}}<span v-if="option.unit">{{option.unit.value}}</span></div></h4></li>
                                    <li>
                                        <h4><label>Status:</label>{{ item.data[track_data.dataIndex] > item.markLine.data[0].yAxis ? 'High' : item.data[track_data.dataIndex] < item.markLine.data[1].yAxis ? 'Low' : 'In Range'}}</h4>
                                        <div class="flag" :class="item.data[track_data.dataIndex] > item.markLine.data[0].yAxis ? 'high' : item.data[track_data.dataIndex] < item.markLine.data[1].yAxis ? 'low' : 'winner'">
                                            {{ item.data[track_data.dataIndex] > item.markLine.data[0].yAxis ? 'H' : item.data[track_data.dataIndex] < item.markLine.data[1].yAxis ? 'L' : '★'}}
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                    <div class="section_title">History <span class="show_hide" @click="showHistory = !showHistory">{{ showHistory ? 'Hide' : 'Show' }}</span></div>
                    <div class="scroll_table">
                        <table class="chart_data" v-show="showHistory" v-if="option.name == 'Circumference'">
                            <tr>
                                <th>Date</th>
                                <th v-for="(item, i) in option.chart" :key="i">{{item.name}}</th>
                            </tr>
                            <tr v-for="(date, d) in chart.xAxis.data" :key="d">
                                <td class="date">{{date}}</td>
                                <td v-for="(item, i) in option.chart" :key="i">
                                    <h5>
                                        {{ item.data[d] }}<span v-if="option.unit">{{option.unit.value}}</span>
                                        <label class="state" :class="item.data[d] > item.markLine.data[0].yAxis ? 'high' : item.data[d] < item.markLine.data[1].yAxis ? 'low' : 'success'">
                                            {{ item.data[d] > item.markLine.data[0].yAxis ? 'H' : item.data[d] < item.markLine.data[1].yAxis ? 'L' : '★'}}
                                        </label>
                                    </h5>
                                </td>
                            </tr>
                        </table>
                        <table class="chart_data" v-show="showHistory" v-else>
                            <tr>
                                <th>Date</th>
                                <th v-for="(item, i) in chart.series" :key="i">{{item.name}}</th>
                            </tr>
                            <tr v-for="(date, d) in chart.xAxis.data" :key="d">
                                <td class="date">{{date}}</td>
                                <td v-for="(item, i) in chart.series" :key="i">
                                    <h5>
                                        {{ item.data[d] }}<span v-if="option.unit">{{option.unit.value}}</span>
                                        <label class="state" :class="item.data[d] > item.markLine.data[0].yAxis ? 'high' : item.data[d] < item.markLine.data[1].yAxis ? 'low' : 'success'">
                                            {{ item.data[d] > item.markLine.data[0].yAxis ? 'H' : item.data[d] < item.markLine.data[1].yAxis ? 'L' : '★'}}
                                        </label>
                                    </h5>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { VueEcharts } from 'vue3-echarts'

    export default {
        name: 'Track Chart',
        data(){
            return{
                show_goal: 1,
                rangeGoals: 1,
                high_limit: 0,
                low_limit: 0,
                updateGoal: false,
                updateTarget: false,
                selectSpecificTarget: 'Greater Than',
                specificTargets:[
                    'Greater Than',
                    'Less Than',
                    'Equal To'
                ],
                canvas_height: 350,
                chart : {
                    legend: {
                        data: [],
                        right: 0,
                        top: '5%',
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: null,
                        className: 'charts_tooltip',
                        textStyle: {
                            fontSize: 11,
                            color: '#5a5a5a',
                        },
                        axisPointer: {
                            type: "none"
                        }
                    },
                    grid: {
                        left: 25,
                        right: 25,
                        bottom: 80,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Sep 24', 'Sep 25', 'Sep 26', 'Sep 27', 'Sep 28', 'Sep 29', 'Sep 30', 'Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', 'Oct 19', 'Oct 20', 'Oct 21', 'Oct 22', 'Oct 23'],
                        boundaryGap: Boolean,
                        offset: 10,
                        axisTick: {
                            length: 4,
                            lineStyle:{
                                type: "solid",
                                color: "#2F7FED",
                                cap: "round",
                                width: 0
                            }
                        },
                        axisLine:{
                            show: false,
                            lineStyle:{
                                color: "#E8E7E7"
                            }
                        },
                        axisLabel: {
                            color: '#4D4D4D'
                        },
                    },
                    yAxis: {
                        type: 'value',
                        offset: 15,
                        splitLine: {
                            show: false
                        }
                    },
                    series: [],
                    dataZoom: [{
                        start: 0,
                        end: 22,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 22,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 10,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 7,
                                }],
                            }
                        },
                    ]
                },
                track_details: false,
                track_data: {
                    seriesName: '',
                    componentIndex: null,
                    value: null,
                    date: '',
                    color: '',
                    dataIndex: null
                },
                pie_chart: {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '5%',
                        left: 'center',
                        selectedMode: false
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            center: ['50%', '70%'],
                            startAngle: 180,
                            label: {
                                show: true,
                                opacity: 1,
                                formatter(param) {
                                    return Math.round(param.percent * 2) + '%';
                                }
                            },
                            data: []
                        }
                    ]
                },
                showHistory: false,
                drawer_type: '',
                selectScope: false,
                scopes:[
                    {
                        name: 'Neck',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'insideBottom',
                            distance: 10,
                            opacity: 1,
                            formatter: function(params) {
                                return `${params.value}`;
                            }
                        },
                        data: [30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25],
                        itemStyle: {
                            color: '#2f7eed',
                            opacity: 1,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 20,
                            symbolOffset: [0, -15],
                            label: {
                                fontSize: 10,
                                color: '#fff'
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                {name: 'Max', yAxis: 40},
                                {name: 'Min', yAxis: 30},
                            ],
                            label:{
                                position: 'end',
                            },
                            lineStyle:{
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 40 },
                                    { yAxis: 30 }
                                ],
                            ],
                            itemStyle: {
                                color: 'rgba(47,127,237,0.08)',
                                opacity: 1,
                            },
                        }
                    },
                    {
                        name: 'Shoulder',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'insideBottom',
                            distance: 10,
                            opacity: 1,
                            formatter: function(params) {
                                return `${params.value}`;
                            }
                        },
                        data: [35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20],
                        itemStyle: {
                            color: '#2f7eed',
                            opacity: 0.80,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 20,
                            symbolOffset: [0, -15],
                            label: {
                                fontSize: 10,
                                color: '#fff'
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                {name: 'Max', yAxis: 35},
                                {name: 'Min', yAxis: 25},
                            ],
                            label:{
                                position: 'end',
                            },
                            lineStyle:{
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 35 },
                                    { yAxis: 25 }
                                ],
                            ],
                            itemStyle: {
                                color: 'rgba(47,127,237,0.08)',
                                opacity: 1,
                            },
                        }
                    },
                    {
                        name: 'Biceps',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'insideBottom',
                            distance: 10,
                            opacity: 1,
                            formatter: function(params) {
                                return `${params.value}`;
                            }
                        },
                        data: [25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30],
                        itemStyle: {
                            color: '#2f7eed',
                            opacity: 0.60,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 20,
                            symbolOffset: [0, -15],
                            label: {
                                fontSize: 10,
                                color: '#fff'
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                {name: 'Max', yAxis: 25},
                                {name: 'Min', yAxis: 15},
                            ],
                            label:{
                                position: 'end',
                            },
                            lineStyle:{
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 55 },
                                    { yAxis: 35 }
                                ],
                            ],
                            itemStyle: {
                                color: 'rgba(47,127,237,0.08)',
                                opacity: 1,
                            },
                        }
                    },
                ],
                stool_type: ['Severe Constipation', 'Mild Constipation', 'Normal', 'Normal', 'Lacking Fiber', 'Mild Diarrhea', 'Severe Diarrhea']
            }
        },
        components:{
            VueEcharts
        },
        props: {
            option: Object,
        },
        emits: ['update:option'],

        mounted(){
            const vm = this;
            vm.chartData();
            if(vm.option.name !== 'Vitamins' && vm.option.name !== 'Minerals' && vm.option.name !== 'Supplements' && vm.option.name !== 'Pain' && vm.option.name !== 'Workout' && vm.option.name !== 'Sleep'){
                vm.goalStat();
                vm.high_limit_stat();
                vm.low_limit_stat();
                vm.rangeStat();
                vm.allMarkPoint();
            }
        },
        watch: {
            rangeGoals(val){
                const vm = this;
                vm.$emit('update:option.isGoalRange', val);
            },
            show_goal(val){
                const vm = this;
                if(vm.chart.series.length == 1){
                    vm.chart.series[0].markLine.lineStyle.opacity = val;
                    vm.chart.series[0].markArea.itemStyle.opacity = val;
                }else{
                    for(let g = 0; g < vm.chart.series.length; g++) {
                        if(vm.option.name == 'Macros'){
                            vm.chart.series[g].markLine.lineStyle.opacity = 0;
                            vm.chart.series[g].markArea.itemStyle.opacity = 0;
                        } else{
                            vm.chart.series[g].markLine.lineStyle.opacity = val;
                            vm.chart.series[g].markArea.itemStyle.opacity = val;
                        }
                    }
                }
            },
            high_limit(val){
                const vm = this;
                vm.chart.series[0].markLine.data[0].yAxis = val;
                vm.chart.series[0].markArea.data[0][0].yAxis = val;
            },
            low_limit(val){
                const vm = this;
                vm.chart.series[0].markLine.data[1].yAxis = val;
                vm.chart.series[0].markArea.data[0][1].yAxis = val;
            },
            
            chart: {
                handler: function(newValue) {
                    const vm = this;
                    vm.$refs.chart.refreshChart();
                },
                deep: true
            }
        },
        methods:{
            goalStat(){
                const vm = this;
                vm.show_goal = vm.option.chart[0].markLine.lineStyle.opacity;
            },
            high_limit_stat(){
                const vm = this;
                vm.high_limit = vm.option.chart[0].markLine.data[0].yAxis;
            },
            low_limit_stat(){
                const vm = this;
                vm.low_limit = vm.option.chart[0].markLine.data[1].yAxis;
            },
            handleHighMark(n){
                const vm = this;
                vm.chart.series[n].markArea.data[0][0].yAxis = vm.chart.series[n].markLine.data[0].yAxis;
            },
            handleLowMark(n){
                const vm = this;
                vm.chart.series[n].markArea.data[0][1].yAxis = vm.chart.series[n].markLine.data[1].yAxis;
            },
            closeUpdateGoal(){
                const vm = this;
                vm.updateGoal = false;
            },
            closeSelectScope(){
                const vm = this;
                vm.selectScope = false;
            },
            rangeStat(){
                const vm = this;
                vm.rangeGoals = vm.option.isGoalRange;
            },
            chartData(){
                const vm = this;
                if(vm.option.name == 'Vitamins' || vm.option.name == 'Minerals' || vm.option.name == 'Supplements' || vm.option.name == 'Pain' || vm.option.name == 'Workout' || vm.option.name == 'Sleep'){
                    vm.chart = vm.option.chart;
                    vm.canvas_height = (vm.chart.series.length * 50) + 80;
                }else{
                    vm.chart.series = vm.option.name == 'Circumference' ? vm.scopes : vm.option.chart;
                    if(vm.chart.series.length == 1){
                        vm.chart.xAxis.boundaryGap = false;
                        vm.chart.tooltip.axisPointer.type = "none";
                        vm.chart.legend.data = [],

                        vm.chart.tooltip.formatter = function (params) {
                            // return (params[0].value > vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #ffa0a0;"></i> High <br />' : params[0].value < vm.chart.series[0].markLine.data[1].yAxis ? '<i class="fas fa-flag-checkered" style="color: #a0d3ff;"></i> Low <br />' : '') + params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.option.name + ': ' + params[0].value + (vm.option.unit.value == 'Ratings' ? '<i class="fas fa-star" style="font-size:9px;padding-left:3px;"></i>' : vm.option.unit.value ) + '</div>';
                            if(vm.option.name == 'Stool'){
                                return (params[0].value > vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #ffa0a0;"></i> Unwell <br />' : params[0].value < vm.chart.series[0].markLine.data[1].yAxis ? '<i class="fas fa-flag-checkered" style="color: #a0d3ff;"></i> Unwell <br />' : '') + params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.option.name + ': ' + vm.stool_type[ (params[0].value) - 1 ] + '</div>';
                            } else{
                                return (params[0].value > vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #ffa0a0;"></i> High <br />' : params[0].value < vm.chart.series[0].markLine.data[1].yAxis ? '<i class="fas fa-flag-checkered" style="color: #a0d3ff;"></i> Low <br />' : '') + params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.option.name + ': ' + params[0].value + (vm.option.unit ? vm.option.unit.value == 'Ratings' ? `/${vm.option.max_val}` : ` ${vm.option.unit.value}` : '' ) + '</div>';
                            }
                        }
                    }else{
                        vm.chart.tooltip.formatter = function (params) {
                            vm.chart.xAxis.boundaryGap = true;
                            vm.chart.tooltip.axisPointer.type = "shadow";
                            for(let i = 0; i < vm.chart.series.length; i++){
                                vm.chart.legend.data.push(vm.chart.series[i].name);
                            }
                            const tooltips = [];
                            tooltips.push(params[0].axisValue);
                            params.forEach(series => {
                                tooltips.push(`${series.marker} ${series.seriesName}: ${series.value}`);
                            });
                            return tooltips.join('<br>');
                        }
                    }
                }
            },
            allMarkPoint(){
                const vm = this;
                let mainData = vm.chart.series;
                if(vm.chart.series.length == 1){
                    vm.chart.series[0].markPoint.data = [];
                    let data = mainData[0].data;

                    for (let i = 0; i < data.length; i++) {
                        const subArray = data[i];
                        const secondItem = subArray;
                        if (secondItem > vm.chart.series[0].markLine.data[0].yAxis) {
                            vm.chart.series[0].markPoint.data.push({ value: 'H', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                        }
                        if (secondItem < vm.chart.series[0].markLine.data[1].yAxis) {
                            vm.chart.series[0].markPoint.data.push({ value: 'L', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                        }
                    }
                }else{
                    for (let s = 0; s < vm.chart.series.length; s++) {
                        vm.chart.series[s].markPoint.data = [];
                        let data = mainData[s].data;

                        for (let i = 0; i < data.length; i++) {
                            const subArray = data[i];
                            const secondItem = subArray;
                            if (secondItem > vm.chart.series[s].markLine.data[0].yAxis) {
                                vm.chart.series[s].markPoint.data.push({ value: 'H', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                            }
                            if (secondItem < vm.chart.series[s].markLine.data[1].yAxis) {
                                vm.chart.series[s].markPoint.data.push({ value: 'L', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                            }
                            // if (secondItem <= vm.chart.series[s].markLine.data[0].yAxis && secondItem >= vm.chart.series[s].markLine.data[1].yAxis) {
                            //     vm.chart.series[s].markPoint.data.push({ value: '★', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                            // }
                        }
                    }
                }
            },
            saveGoal(){
                const vm = this;

                vm.updateGoal = false;
                vm.allMarkPoint();
            },
            selectBar(params) {
                const vm = this;
                let tsum = null;

                if(vm.drawer_type == 'Pain'){
                    vm.track_data = {
                        seriesName: params.seriesName,
                        componentIndex: params.componentIndex,
                        value: params.value,
                        date: params.name,
                        color: params.color,
                        dataIndex: params.dataIndex,
                    };

                    vm.track_details = true; 
                }
                else if(vm.drawer_type !== 'Pain' && params.componentSubType == 'bar'){
                    vm.track_data = {
                        seriesName: params.seriesName,
                        componentIndex: params.componentIndex,
                        value: params.value,
                        date: params.name,
                        color: params.color,
                        dataIndex: params.dataIndex,
                    };

                    vm.pie_chart.series[0].data = [];

                    for(let b = 0; b < vm.chart.series.length; b++){
                        tsum += vm.chart.series[b].data[vm.track_data.dataIndex];
                        vm.pie_chart.series[0].data.push(
                            {
                                value: vm.chart.series[b].data[vm.track_data.dataIndex],
                                name: vm.chart.series[b].name,
                                itemStyle: {
                                    color: vm.chart.series[b].itemStyle.color,
                                    opacity: `${vm.chart.series[b].itemStyle.opacity ? vm.chart.series[b].itemStyle.opacity : 1}`,
                                },
                            }
                        );
                    }
                    vm.pie_chart.series[0].data.push(
                        {
                            value: tsum,
                            itemStyle: {
                                color: 'none',
                                decal: {
                                    symbol: 'none'
                                }
                            },
                            label: {
                                show: false,
                                formatter: '{b}: {d}'
                            }
                        }
                    )

                    vm.track_details = true;
                }
            },
            openTargetType(ev){
                let parEl = ev.currentTarget.closest('.target_drops');
                let el = parEl.querySelector('.dropdown_wpr');
                const allEl = document.querySelectorAll('.goal_item .dropdown_wpr');
                
                if (el.classList.contains('active')){
                    el.classList.remove('active');
                } else {
                    for(let i = 0; i < allEl.length; i++){
                        allEl[i].classList.remove('active');
                    }
                    el.classList.add('active');
                }
            },
            handleScopes(elm, elmArr){
                let hasElm = elmArr.findIndex((att) => att.name === elm.name);

                if(hasElm == -1){
                    if(elmArr.length < 3){
                        elmArr.push(elm);
                    }
                } else{
                    elmArr.splice(hasElm, 1);
                }
            }
        }
    }
</script>

<style scoped>
.chart_title{
    font-size: 18px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 10px 0;
}
.chart_title img{
    max-height: 30px;
    width: auto;
    padding-right: 10px;
}
.chart_title .tag{
    background: #d9d9d9;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    margin-left: 10px;
}
.widgets_wpr{
    padding: 20px 30px;
    margin-bottom: 40px;
    position: relative;
}
.widgets_wpr .slider_cover{
    width: 100%;
    height: 20px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 49px;
}
.widgets_wpr:hover .slider_cover{
    display: none;
}
.widgets_wpr .header{
    align-items: center;
}
.item_unit{
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 15px;
    color: #5a5a5a;
    margin: 0 10px 0 auto;
}
.item_unit span{
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
}
.chart_setup{
    font-size: 16px;
    position: relative;
    cursor: pointer;
}
.chart_setup .goal_option{
    position: absolute;
    width: 330px;
    right: 0;
    top: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 20px rgb(0,0,0,0.15);
    margin-top: 10px;
    max-height: 320px;
    overflow-y: scroll;
    z-index: 1;
    display: none;
    flex-wrap: wrap;
    opacity: 0;
}
.chart_setup .goal_option::-webkit-scrollbar{
    width: 4px;
}
.chart_setup .goal_option::-webkit-scrollbar-thumb{
    border-radius: 2px;
    background-color: #dbdbdb;
}
.chart_setup .goal_option .subgoal_item{
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0 20px 0;
}
.chart_setup .goal_option .subgoal_item .title{
    flex-grow: 1;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #121525;
    padding: 5px 15px;
    margin: 0 -15px;
    background: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 1;
}
.chart_setup .goal_option .goal_item{
    width: 50%;
    padding: 15px 15px 25px 15px;
}
.chart_setup .goal_option .goal_item:nth-of-type(1){
    border-right: 1px solid #e9e9e9;
}
.chart_setup .goal_option .goal_item h4{
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.chart_setup .goal_option .goal_item ul{
    flex-direction: column;
    margin-top: 15px;
}
.chart_setup .goal_option .goal_item ul li {
    width: 100%;
    height: auto;
    border: 0;
}
.chart_setup .goal_option .goal_item ul li label{
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
    padding-bottom: 3px;
    display: block;
}
.chart_setup .goal_option .goal_item ul li .field_area{
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
}
.chart_setup .goal_option .goal_item ul li .field_area input{
    height: 28px;
    width: 100%;
    padding: 0 10px;
    font-size: 13px;
    background: transparent;
}
.chart_setup .goal_option .goal_item ul li .field_area .unit{
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 28px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}
.chart_setup .goal_option .action_wpr{
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: #fff;
    border-top: 1px solid #e9e9e9;
    width: 100%;
    margin: 0;
    padding: 12px 20px;
}
.chart_setup .goal_option .action_wpr .btn {
    min-width: auto;
    height: 28px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    padding: 6px 15px;
}
.chart_setup .goal_option.active {
    animation: dropMove 0.5s ease-in-out;
    display: flex;
    opacity: 1;
}
.chart_setup .target_drops {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #5a5a5a;
    padding: 6px 25px 6px 10px;
}
.chart_setup .target_drops > i {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e9e9e9;
    font-size: 13px;
    color: #767676;
    pointer-events: none;
}
.chart_setup .target_drops .dropdown_wpr{
    z-index: 3;
}
.chart_setup .target_drops .dropdown_wpr ul{
    margin: 0;
    gap: 0;
}
.widgets_wpr span.chart_title{
    left: -20px;
    min-width: 80px;
    display: flex;
    justify-content: center;
}
@keyframes dropMove {
    0% {
        margin-top: 1px;
        opacity: 0;
    }
    100% {
        margin-top: 10px;
        opacity: 1;
    }
}
.modal.track_modal{
    z-index: 13;
}
.track_modal .modal_body{
    background: #fff;
}
.range_limit{
    border-radius: 8px;
    margin-bottom: 30px;
}
.half_pie_wpr{
    height: 230px;
    overflow: hidden;
}
.range_limit .scope_details{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 30px;
}
.range_limit .scope_details .scope_item{
    border: 2px solid #fff;
    background: #ebf3ff;
    border-radius: 3px;
    flex-grow: 1;
    padding: 10px;
    display: flex;
    align-items: flex-end;
}
.range_limit .scope_details .scope_item h4{
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
}
.range_limit .scope_details .scope_item .item_data{
    font-size: 15px;
    line-height: 18px;
    color: #2f7eed;
    font-weight: 500;
    padding-left: 10px;
}
.range_limit .scope_details .scope_item .item_data span{
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    padding-left: 3px;
}
/* .range_limit .scope_details .scope_item .item_data{
    background: #f5f5f5;
    border-radius: 3px;
    flex-grow: 1;
    padding: 10px 15px;
    display: flex;
    align-items: center;
} */
.range_limit h3{
    font-size: 17px;
    line-height: 22px;
    color: #121525;
    font-weight: 400;
    padding: 10px 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
}
.range_limit h3 .range_details{
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
    margin: 0 -7px 3px -7px;
}
.range_limit h3 .range_details span{
    padding: 0 7px;
    border-right: 1px solid #ccc;
}
.range_limit h3 .range_details span:last-of-type{
    border: 0;
}
.range_limit ul {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    gap: 10px;
}
.range_limit ul li {
    background: #f5f5f5;
    border-radius: 5px;
    flex-grow: 1;
    padding: 12px 20px;
    display: flex;
    align-items: center;
}
.range_limit ul li h4{
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}
.range_limit ul li h4 label{
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    padding-bottom: 7px;

}
.range_limit ul li .flag{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    font-size: 13px;
    line-height: 25px;
    font-weight: 500;
    background: #999;
    color: #fff;
    margin-left: auto;
}
.range_limit ul li .flag.winner{
    display: none;
}
.range_limit ul li span{
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    padding-left: 3px;
}
.pain_limit{
    margin-bottom: 30px;
}
.pain_limit .pain_item{
    background: #f5f5f5;
    border-radius: 5px;
    padding: 12px 20px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.pain_limit .pain_item label{
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    padding-bottom: 7px;
    display: block;
}
.pain_item .positions ul{
    display: flex;
    margin: 0 -3px;
}
.pain_item .positions ul li{
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 400;
    padding: 2px 10px;
    border-radius: 10px;
    background: #888;
    margin: 3px;
}
.pain_limit .pain_item h4{
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pain_limit .pain_item h4 label{
    padding: 3px 0 0 0;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 1px;
}
.additional_notes{
    background: #f5f5f5;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 30px;
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
}
.section_title{
    font-size: 20px;
    line-height: 28px;
    color: #0D0D0D;
    font-weight: 400;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}
.show_hide{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    padding: 3px 10px;
    border-radius: 15px;
    margin-left: auto;
}

.scroll_table{
    overflow-x: auto;
    border: 1px solid #e9e9e9;
    border-bottom: 0;
    border-radius: 5px;
    max-height: 400px;
}
.scroll_table::-webkit-scrollbar{
    width: 10px;
    height: 10px;
    background: #fbfbfb;
}
.scroll_table::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 5px;
}
.chart_data {
    min-width: 350px;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
}
.chart_data th, .chart_data td{
    padding: 10px 0;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
}
.chart_data th:last-child, .chart_data td:last-child{
    border-right: 0;
}
.chart_data th{
    background: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 1;
}
.chart_data th:first-child{
    z-index: 2;
    left: 0;
}
.chart_data td{
    background: #fff;
    color: #5a5a5a;
    min-width: 100px;
}

.chart_data td.date{
    min-width: 100px;
    position: sticky;
    left: 0;
    z-index: 1;
    border-right: 0;
}
.chart_data th:first-child:after, .chart_data td.date:after{
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid #e9e9e9;
}
.chart_data td h5{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chart_data td h5 span{
    font-size: 9px;
    line-height: 11px;
    padding-left: 2px;
}
.chart_data td h5 label.state{
    font-size: 10px;
    margin-left: 5px;
}
.chart_data td h5 label.state{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #999;
    text-align: center;
    font-size: 10px;
    line-height: 15px;
    font-weight: 500;
    color: #fff;
    margin-left: 7px;
}
.chart_data td h5 label.state.success{
    display: none;
}

.scope_box{
    width: auto;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    position: relative;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #121525;
    padding: 8px 30px 8px 10px;
    margin-right: 15px;
    min-width: 120px;
    cursor: pointer;
}
.scope_box > i{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #eeeeee;
    font-size: 13px;
    color: #999;
}
.scope_box .dropdown_wpr{
    overflow: hidden;
}
.scope_box .dropdown_wpr ul{
    padding: 5px 15px;
    justify-content: flex-start;
    gap: 0;
}
.scope_box .dropdown_wpr ul li{
    border: 0;
    padding: 10px 0;
    height: auto;
    min-width: 100px;
    width: auto;
}
.scope_box .dropdown_wpr ul li label.checkbox{
    justify-content: flex-start;
}
.scope_box .dropdown_wpr ul li label.checkbox span i{
    color: #7a7a7a;
}
.scope_box .dropdown_wpr ul li label.checkbox span.active i{
    transform: scale(1);
    opacity: 1;
}
.scope_box .dropdown_wpr ul li label.checkbox h5{
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    font-weight: 500;
    padding-left: 10px;
}

@media(max-width: 499px){
    .widgets_wpr{
        padding: 20px;
    }
    .item_unit{
        font-size: 11px;
        line-height: 15px;
    }
    .item_unit span{
        font-size: 13px;
        line-height: 15px;
    }
    .chart_setup .goal_option{
        right: -12px;
    }
    .widgets_wpr .slider_cover{
        display: none;
    }
}
</style>